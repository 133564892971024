import { render, staticRenderFns } from "./SCMsystem.vue?vue&type=template&id=9138e86e&scoped=true"
import script from "./SCMsystem.vue?vue&type=script&lang=js"
export * from "./SCMsystem.vue?vue&type=script&lang=js"
import style0 from "./SCMsystem.vue?vue&type=style&index=0&id=9138e86e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9138e86e",
  null
  
)

export default component.exports