<template>
  <div style="min-width: 1360px">
    <Header/>
    <headerBanner :content="bannerContent" title="SCM供应链管理系统" @btnClick="bannerClick" imgStyle="height:0%;height:0"
                  contentStyle="width:100%" :bodyStyle="bodyStyle"/>
    <div class="card">
      <div class="card-container">
        <div style="margin-bottom: 40px;" class="card--title">供应链管理系统核心能力</div>
        <div class="card-boxs" style="flex-wrap: wrap;">
          <div class="card1" v-for="(item,i) in dataList" :key="i">
            <img :src="item.icon" style="height: 60px;padding-top: 30px;">
            <div style="padding-top: 20px;">{{ item.title }}</div>
            <div class="card-contents">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card" style="background-color: #F7F8FE;">
      <div class="card-container">
        <div class="card--title" style="margin-bottom: 30px;">供应链系统架构</div>
        <img style="width: 100%;" :src="require('@/assets/img/home/1678955979485_0JB91pnZRK.png')">
      </div>
    </div>
    <div class="card">
      <div class="card-container">
        <div class="card--title" style="margin-bottom: 40px;">供应链系统能为你实现</div>
        <div class="card-boxs">
          <div style="width: 500px;">
            <div class="cell-item" v-for="(card,i) of cardList" :key="i">
              <div class="or-title">{{ card.title }}</div>
              <div class="orContent">{{ card.content }}</div>
            </div>
          </div>
          <img :src="require('@/assets/img/home/gyl.png')" style="width: 655px;height: 420px;">
        </div>
      </div>
    </div>
    <applyTrial ref="dialog" :servicePlatform="'SCM供应链管理系统'"/>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/views/home/newCloudeHeader.vue";
import applyTrial from "@/components/assembly/applyTrial";
import Footer from "@/views/home/newCloudeFooter.vue";
import headerBanner from '@/components/assembly/headerBanner';

export default {
  components: {
    Header,
    Footer,
    headerBanner,
    applyTrial
  },
  data() {
    return {
      bannerContent: '整合和优化供应链的各个环节，如采购、生产、配送和销售，提高供应链的效率和可靠性。实现数据共享和协同工作，减少不必要的沟通和误解，提高工作效率和协作能力。',
      bodyStyle: `background-image:url(${require('@/assets/img/home/scmBanner.png')});background-size: 40%;background-repeat: no-repeat;background-position: right 25px;`,
      dataList: [
        {
          icon: require('@/assets/img/home/4t1grfhBlb5D.png'),
          title: '供应商管理',
          content: "涵盖供应商入驻、认证、考察、合作、绩效评估全流程业务，挖掘优秀供应商、淘汰劣质供应商"
        },
        {
          icon: require('@/assets/img/home/LqkWxRTY3FZx.png'),
          title: '采购管理',
          content: "涵盖了物料采购管理、订单管理、订单变更、退货管理等。通过询比价、合同管理的规范化，提升信息化高度集成"
        },
        {
          icon: require('@/assets/img/home/PltVJEsJIssy.png'),
          title: '商品管理',
          content: "自定义规格属性，独立SKU使得库存、订单、结算清晰明了，管理各渠道商品的审核，确保商品管理灵活高效"
        },
        {
          icon: require('@/assets/img/home/5iKhkzMMCg96.png'),
          title: '订单管理',
          content: "企业采购订单自动生成，订单状态实时更新，及时对账，简化企业采购审批流程，可及时进行订单管控"
        },
        {
          icon: require('@/assets/img/home/BdiAcHgrAY9f.png'),
          title: '仓储管理',
          content: "通过入库、出库、变革管理与作业模式提高仓储管理数据准确性，有效控制并跟踪仓库业务的物流"
        },
        {
          icon: require('@/assets/img/home/GMLZDKemGFTU.png'),
          title: '合同管理',
          content: "合同管理功能为使用电子签名的电子合同，确保签约主体的真实可靠，大大提高了工作效率"
        },
        {
          icon: require('@/assets/img/home/3fInVJez64v9.png'),
          title: '风险管理',
          content: "数字化采购将应用数据捕捉和采集技术，基于大数据进行前瞻性预测分析，实时洞察潜在的风险"
        },
        {
          icon: require('@/assets/img/home/WO7jPI5Sc8nG.png'),
          title: '支付管理',
          content: "满足企业多样化的支付方式，提供灵活专业的资金结算，支持大额网关支付、退款、安全保障、差错处理等服务"
        }
      ],
      cardList: [
        {
          title: '打通“四流”，提高供应链协同水平',
          content: '提升核心企业产业链一体化运作效率； 实现商流、物流、信息流、资金流四流合一； 借助敏前台、强中台的供应链平台，快速应对复杂的商业场景，优化全流程。'
        },
        {title: '全链路数字化建设，实现乘数效应', content: '契合了传统企业的数字化转型需求，构建了打通上中下游的供应链管理平台，为企业提供可选择的能力模块，全链路覆盖，实现企业供应链管理效能的成倍提升。'},
        {title: '授信支付与供应链金融，安全可靠轻松交易', content: '不只是平台技术升级，从可持续发展的角度解决关键性的业务问题，通过供应链可视化深度洞察业务增长和获利的商机，增强核心竞争力。'},
      ]
    }
  },
  methods: {
    bannerClick() {
      this.$refs.dialog.show = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  padding: 60px 0;

  &-container {
    width: 1200px;
    margin: auto;
  }
}

.card1 {
  box-sizing: border-box;
  width: 290px;
  height: 260px;
  border: 1px solid #ddd;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.or-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 18px;
  color: #333;
  margin-top: 30px;
}

.orContent {
  padding-bottom: 24px;
  line-height: 1.5;
  text-align: justify;
  font-size: 14px;
  color: #333;
}

.cell-item {
  border-bottom: 1px dashed #1935DE;

  &:last-child {
    border: 0;
  }
}

.card--title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.card-boxs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-contents {
  padding: 25px;
  font-size: 16px;
  text-align: justify;
  line-height: 1.5;
}
</style>
